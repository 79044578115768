<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <Logo />

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="
                require(`@/assets/images/misc/mask-v2-${
                  $vuetify.theme.dark ? 'dark' : 'light'
                }.png`)
              "
            />
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat v-if="token">
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Cambiar contraseña 🔒
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="changePasswordForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <FormTextFieldPassword
                      v-model="password"
                      label="Nueva contraseña"
                      placeholder="********"
                      :rules="[validators.required]"
                      class="mb-6"
                    />

                    <FormTextFieldPassword
                      v-model="confirm_password"
                      label="Repetir contraseña"
                      placeholder="********"
                      :rules="[
                        validators.required,
                        validators.confirmedValidator(
                          password,
                          confirm_password
                        ),
                      ]"
                      class="mb-6"
                    />

                    <v-btn
                      :loading="loading"
                      :disabled="loading"
                      block
                      type="submit"
                      color="primary"
                    >
                      Cambiar contraseña
                    </v-btn>
                  </v-form>
                </v-card-text>

                <v-card-actions class="d-flex justify-center align-center">
                  <router-link
                    :to="{ name: 'auth-login' }"
                    class="d-flex align-center text-sm"
                  >
                    <v-icon size="24" color="primary">
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>Volver</span>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/general/Logo";
import FormTextFieldPassword from "@/components/forms/TextFieldPassword";
import { mdiChevronLeft } from "@mdi/js";
import { getCurrentInstance, onMounted, ref } from "@vue/composition-api";
import { required, confirmedValidator } from "@core/utils/validation";
import api from "@/services";
import { useRouter } from "@core/utils";
import router from "@/router";

export default {
  components: {
    Logo,
    FormTextFieldPassword,
  },
  setup() {
    const token = ref(null);
    const password = ref("");
    const confirm_password = ref("");
    const loading = ref(false);
    const changePasswordForm = ref(null);
    const { router } = useRouter();

    const vm = getCurrentInstance().proxy;

    const onToggleLoading = () => {
      loading.value = !loading.value;
    };

    const handleFormSubmit = async () => {
      const isFormValid = changePasswordForm.value.validate();

      if (!isFormValid) return;

      onToggleLoading();

      try {
        await api.changePassword({
          password: password.value,
          token: token.value,
        });
        vm.$alert("Contraseña actualizada correctamente.", null, "success");

        router.push({ name: "auth-login" });
      } catch (error) {
      } finally {
        changePasswordForm.value.reset();
      }

      onToggleLoading();
    };

    onMounted(() => {
      const { token: tokenRoute } = router.currentRoute.query;
      if (!token) router.push({ name: "auth-login" });
      token.value = tokenRoute;
    });

    return {
      token,
      password,
      confirm_password,
      loading,
      changePasswordForm,

      validators: {
        required,
        confirmedValidator,
      },

      icons: {
        mdiChevronLeft,
      },

      handleFormSubmit,
    };
  },
};
</script>

<style lang="scss">
@import "@core/preset/preset/pages/auth.scss";
</style>
